#collection-layout {
    display: grid;
    width: 100%;
    gap: 1rem;
    grid-template-areas:
        "filter-button sort-options"
        "filter-items filter-items"
}

#filter-button-section {
    grid-area: filter-button;
}

#sort-options-section {
    grid-area: sort-options;
}

#filter-items-section {
    grid-area: filter-items;
    padding-top: 0;
}

.hidden {
    display: none;
}